import React, { useState, useEffect, useRef } from "react";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";
// import DetailAnalysis from "./DetailAnalysis";
import axios from "axios";
import ResetButton from "assets/resetbutton.jpg";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBreadcrumbs from "components/commontools/ToolsBreadcrumbs";
import ToolsBanner from "components/commontools/toolsBanner";
import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import useDebounce from "components/QualityTools/useDebounce";
import { Languages } from "components/QualityTools/LanguagesData";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import { parseResult } from "utils/toolsresult";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import { Tooltip } from "react-tooltip";
import CleanButton from "assets/cleanBtn.svg";
import ToolFaq from "components/QualityTools/ToolFaq";
import { GlossaryToolFaqData } from "components/FAQ/config";
const GlossaryGenerator = () => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [TransFromOpen, setTransFromOpen] = useState(false);
  const [TransToOpen, setTransToOpen] = useState(false);
  const [FromSearchInput, setFromSearchInput] = useState("");
  const [ToSearchInput, setToSearchInput] = useState("");
  const [sameLanguageError, setSameLanguageError] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  // const [showDetailAnalysis, setShowDetailAnalysis] = useState(false);
  const [detectedLanguage, setDetectedLanguage] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const refDiv = useRef(null);
  const outputRef = useRef(null);
  const TranslateFromRef = useRef(null);
  const debouncedInputText = useDebounce(inputText, 2000);
  const debouncedOutputText = useDebounce(outputText, 2000);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);
  const [shareId, setShareId] = useState("");
  const [copySuccess, setCopySuccess] = useState("Copy");
  const [isLgScreen, setIsLgScreen] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsLgScreen(window.innerWidth > 1024);
      const handleResize = () => {
        setIsLgScreen(window.innerWidth > 1024);
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  // console.log(isProduction, "isproduction");
  const { data } = useFetchLanguages();

  useEffect(() => {
    if (refDiv.current) {
      setDivHeight(refDiv.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectSourceLanguage(debouncedInputText);
    }
  }, [debouncedInputText]);
  useEffect(() => {
    if (debouncedOutputText?.trim()?.length >= 3) {
      detectTargetLanguage(debouncedOutputText);
    }
  }, [debouncedOutputText]);
  const detectSourceLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const language = response.data?.data?.language_probability?.code;
      setTranslateFromInput(language);
      const languageName = Languages?.find((lang) => lang?.code === language);

      if (languageName?.name) {
        setInputLang(language);
      }

      // const languageCodeName = response.data?.data?.language_probability?.name;
      // setTranslateFromInput(languageCodeName);
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };
  const detectTargetLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const languageTo = response.data?.data?.language_probability?.code;
      setTranslateToInput(languageTo);
      const languageToName = Languages.find(
        (lang) => lang?.code === languageTo
      );

      if (languageToName?.name) {
        setOutputLang(languageTo);
      }
      // const languageCode = response.data?.data?.language_probability?.name;

      // setTranslateToInput(languageCode);
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };

  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError(false);
    setShowOutput(false);
    try {
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "glossaryGenerator",
        source_text: inputText,
        translation: outputText,
        source_language_code: transtalteFromInput,
        target_language_code: transtalteToInput,
      });

      // setAnalysisResult(response);
      const newShareId = response?.meta?.share_id;

      setShareId(newShareId);

      setShowOutput(true);
      navigate(`/tools/glossary-generator?share-id=${newShareId}`)

    } catch (error) {
      // console.error("Error during quality analysis:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
 
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  const displayShareId = shareId ? shareId : UpdatreshareId;
  useEffect(() => {
    if (!displayShareId) return;
    const fatchAnalysis = async () => {
      setLoading(true);
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        setAnalysisResult(response);
        setShowOutput(true);
        const { source_text: sourceText, translation_text: targetText } =
          response.meta;

        setInputText(sourceText);
        setOutputText(targetText);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };
    fatchAnalysis();
  }, [displayShareId]);

  const findLanguageNameByCode = (code) => {
    const language = Languages.find((lang) => lang.code === code);
    return language ? language.name : null;
  };

  const sourceLanguageName = findLanguageNameByCode(
    analysisResult?.meta?.source_language_code
  );
  const targetLanguageName = findLanguageNameByCode(
    analysisResult?.meta?.target_language_code
  );

  const isButtonDisabled =
    !transtalteFromInput || !transtalteToInput || !inputText || !outputText;

  const handleReset = async () => {
    await handleClick();
  };
  const resultValue = parseResult(analysisResult?.result);
  const glossary = resultValue?.glossary;

  // const resultLength = resultValue && Object?.keys(resultValue).length;
  // console.log(resultLength?.length ,"result");
  useEffect(() => {
    if (refDiv?.current) {
      setDivHeight(refDiv?.current?.base?.offsetHeight);
    }
  }, [refDiv, analysisResult]);
  const handleSourceClearText = () => {
    if(UpdatreshareId){
    setInputText("");
    setOutputText("")
    setInputLang("");
    setOutputLang("")
    setTranslateToInput("");
    setTranslateFromInput("");
    setAnalysisResult(null);
    setShowOutput(false);
    setWordCountInput(0);
    setWordCountOutput(0);
    navigate(`/tools/glossary-generator/`);
  }
  setInputLang("")
  setInputText("")
  setWordCountInput(0);
  };
  const handleTargetClearText = () => {
    if(UpdatreshareId){
    setOutputText("");
    setInputText("")
    setInputLang("")
    setOutputLang("");
    setTranslateToInput("");
    setTranslateFromInput("");
    setAnalysisResult(null);
    setShowOutput(false);
    setWordCountInput(0);
    setWordCountOutput(0);
    navigate(`/tools/glossary-generator/`);
    }
    setOutputText("");
    setOutputLang("");
    setWordCountOutput(0);
  };

  const copyText = () => {
    if (outputRef.current) {
      const textToCopy = outputRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };
  const downloadCSV = () => {
    if (!resultValue || !Array.isArray(resultValue.glossary)) return;

    const csvRows = [];
    csvRows.push([sourceLanguageName, targetLanguageName]);

    resultValue.glossary.forEach((item) => {
      csvRows.push([item.source_term, item.translated_term]);
    });

    const csvString = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "glossary.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Layout>
      <SEO
        title="Bilingual Glossary"
        description="Generate bilingual glossaries instantly with our free AI-powered tool. It's the ideal solution for quickly creating glossaries tailored to your project."
        slug="/tools/glossary-generator"
      />
      <div className="bg-[#F5F5F5] ">
        <div className="w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>

        <div className="max-w-7xl mx-auto px-4 py-12 lg:py-[60px]">
          <ToolsBanner
            text="Bilingual Glossary Generator"
            title="Generate a Bilingual Glossary for Consistent Translations"
            description="  Enter your source text and translation to generate a free bilingual glossary of key terms for your project. Terms such as proper names and street names that may not need to be translated will remain unchanged."
          />
          <div className="relative lg:flex gap-3 mt-12">
            <div className="flex flex-col gap-3 w-full lg:w-[70%]">
              <div className="lg:flex items-center  rounded-lg shadow-lg ">
                <ToolInput
                  lang={inputLang}
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
                  setOutputText={setOutputText}
                  setInputLang={setInputLang}
                  setOutputLang={setOutputLang}
                  setTranslateToInput={setTranslateToInput}
                  setTranslateFromInput={setTranslateFromInput}
                  setAnalysisResult={setAnalysisResult}
                  setShowOutput={setShowOutput}
                  setWordCount={setWordCountInput}
                  iscleanBtn={true}
                  refDiv={refDiv}
                  result={analysisResult}
                  divHeight={divHeight}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
  
                >
                  {" "}
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                          Source text
                        </p> 
                        {inputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={handleSourceClearText}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
                </ToolInput>

                <div className="hidden lg:block h-full border border-[#DEDEDE] "></div>

                <ToolInput
                  lang={outputLang}
                  text={outputText}
                  wordCount={wordCountOutput}
                  setText={setOutputText}
                  setWordCount={setWordCountOutput}
                  refDiv={refDiv}
                  result={analysisResult}
                  divHeight={divHeight}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
                  isTranslation ={true}

                >
               

                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                        Translated text
                        </p> 
                        {outputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={handleTargetClearText}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
                </ToolInput>
              </div>
            </div>

            <ToolOutput
              handleClick={handleClick}
              result={analysisResult}
              isButtonDisabled={isButtonDisabled}
              ResetButton={ResetButton}
              loading={loading}
              handleReset={handleReset}
              showOutput={showOutput}
              error={error}
              ref={refDiv}
              divHeight={divHeight}
              isRefreshBtn={true}
              buttontext="Generate Glossary"
              text="Your results will appear here. <br /> Please enter your texts and click 'Generate Glossary' to begin."
            >
              <ul
                style={{ maxHeight: isLgScreen ? `${divHeight - 22}px` : "" }}
                className=" w-full text-sm text-left rtl:text-right relative overflow-y-auto  shadow-md sm:rounded-lg"
                ref={outputRef}
              >
                <li className="grid grid-cols-2">
                  <p className="px-6 py-3 bg-[#BDD3FF] border-none outline-none text-[#575757] font-opensans text-center font-normal">
                    {sourceLanguageName}
                  </p>
                  <p className="px-6 py-3 bg-[#E0EBFF] border-none outline-none text-[#575757] font-opensans text-center font-normal">
                    {targetLanguageName}
                  </p>
                </li>

                <div className="mt-2 grid gap-2">
                  {glossary &&
                    glossary?.map((item, index) => (
                      <li className="flex" key={index}>
                        <p className="p-3 w-full lg:max-w-[174px] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                          {item.source_term}
                        </p>
                        <p className="p-3 bg-[#F0F5FF] w-full lg:max-w-[174px] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                          {item.translated_term}
                        </p>
                      </li>
                    ))}
                </div>
                {/* <p className="p-3 text-[#6E6E6E] font-opensans text-sm font-bold">
                  {resultValue && Object?.keys(resultValue).length} Glossary
                  terms{" "}
                </p> */}
              </ul>
              <div className="lg:absolute lg:bottom-3 lg:left-0 w-full bg-[#ffffff] mt-6 py-4 px-3 rounded-lg flex items-center justify-end gap-5">
                <div className="flex gap-4 items-center justify-between  ">
                  <button
                    data-tooltip-id="copy-tooltip"
                    data-tooltip-content={copySuccess}
                    onClick={copyText}
                    className=""
                  >
                    <img src={CopyIcon} alt="/copyicons" />
                  </button>
                  <button
                    data-tooltip-id="download-tooltip"
                    data-tooltip-content="Download"
                    onClick={downloadCSV}
                    className=""
                  >
                    <img src={DownloadIcon} alt="/downloadicon" />
                  </button>
                </div>
                <Tooltip
                  id="copy-tooltip"
                  place="top"
                  multiline={true}
                  className="z-50"
                />
                <Tooltip
                  id="download-tooltip"
                  place="top"
                  multiline={true}
                  className="z-50"
                />
              </div>
            </ToolOutput>
          </div>
          <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
            Enter your source text and translation to generate a free bilingual
            glossary of key terms for your project. Terms such as proper names
            and street names that may not need to be translated will remain
            unchanged.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={GlossaryToolFaqData} />
    </Layout>
  );
};

export default GlossaryGenerator;
